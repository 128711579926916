import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.config.productionTip = false
Vue.use(VueLuckyCanvas)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

