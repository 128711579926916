import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: ()=> import('@/views/login')
    },
    {
      path: '/register',
      name: 'register',
      component: ()=> import('@/views/register')
    },
    {
      path: '/',
      name: 'index',
      component: ()=> import('@/views/index')
    },
    {
      path: '/manghe',
      name: 'manghe',
      component: ()=> import('@/views/manghe')
    },
    {
      path: '/zhuanpan',
      name: 'zhuanpan',
      component: ()=> import('@/views/zhuanpan')
    },
    {
      path: '/fanpan',
      name: 'fanpan',
      component: ()=> import('@/views/fanpan')
    },
    {
      path: '/friends',
      name: 'friends',
      component: ()=> import('@/views/friends')
    },
    {
      path: '/friendInfo',
      name: 'friendInfo',
      component: ()=> import('@/views/friendInfo')
    },
    {
      path: '/cars',
      name: 'cars',
      component: ()=> import('@/views/cars')
    },
    {
      path: '/order',
      name: 'order',
      component: ()=> import('@/views/order')
    },
    {
      path: '/oir',
      name: 'openIdRedirect',
      component: ()=> import('@/views/openidRedirect.vue')
    },
    {
      path: '/prizeRecord',
      name: 'prizeRecord',
      component: ()=> import('@/views/prizeRecord.vue')
    },
    {
      path: '/orderInfo',
      name: 'orderInfo',
      component: ()=> import('@/views/orderInfo.vue')
    },
    {
      path: '/album',
      name: 'album',
      component: ()=> import('@/views/album.vue')
    },
    {
      path: '/albumInfo',
      name: 'albumInfo',
      component: ()=> import('@/views/albumInfo.vue')
    },
    {
      path: '/card',
      name: 'card',
      component: ()=> import('@/views/card.vue')
    },
    {
      path: '/user',
      name: 'user',
      component: ()=> import('@/views/user')
    },
    {
      path: '/coupon',
      name: 'coupon',
      component: ()=> import('@/views/coupon')
    },
  ]
})
